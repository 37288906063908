import * as observability from '@/lib/observability.ts'
import * as analytics from '@/lib/analytics.ts'
import * as errors from '@/api/errors.ts'
import { ArgsType, SessionInfoType } from '@/store/useChat.ts'
import { handleNotFoundError, handleSchemaValidationError, handleUnknownError } from '@/api/error-handlers.ts'
import { BASE_URL } from '@/lib/config.ts'
import {createHeaders} from '@/api/utils.ts'
import { useSessionStore } from '@/store/useSessionStore.ts'
import { createNewSession } from '@/api/sessions.ts'

interface cadDataType {
  [key: string]: any
}

interface CadDataPostRequestBody {
  geometry: cadDataType
  userMessage: string
}

export function useCadData() {
  const getSessionAndRevision = async (numberOfSession: number | undefined) => {
    if (numberOfSession === undefined) {
      const newSession = await createNewSession()
      useSessionStore.getState().setSessionNum(newSession.sessionNum)
      useSessionStore.getState().setRevisionNum(newSession.revisionNum)

      return newSession
    } else {
      return {
        sessionNum: numberOfSession,
      }
    }
  }

  async function postCadData(content: CadDataPostRequestBody): Promise<any> {
    const sessionNum = useSessionStore.getState().sessionNum
    const currentSession = await getSessionAndRevision(sessionNum)

    const url = `${BASE_URL}/api/v1/session/${currentSession.sessionNum}/cad_data`

    const options = {
      method: 'POST',
      headers: await createHeaders(),
      body: JSON.stringify(content),
    }

    try {
      const response = await fetch(url, options)
      const params: any = { sessionNum: currentSession.sessionNum, revisionNum: 1 }
      await handleError(response, params, {})
      return response.json()
    } catch (error: any) {
      observability.captureException(`Failed to post cad data`, {
        error,
        tags: {
          url,
          authorization: options.headers.Authorization,
        },
      })

      analytics.track('Post Cad Data Error', { error })

      throw new errors.UnknownApiError(`Error with fetching data from ${url}`)
    }
  }

  async function handleError(response: Response, params: SessionInfoType, data: any) {
    if (response.ok) {
      return
    }
    const error = await response.json()
    const args: ArgsType = {
      headers: response.headers,
      url: response.url,
      method: 'PUT',
      params,
      data,
    }

    if (error.isSchemaValidationError) {
      handleSchemaValidationError(error, args)
    }

    if (response.status === 404) {
      handleNotFoundError(error, args)
    }

    if (response.status === 400 && error?.reason === 'Policy Violation') {
      observability.captureException(`Policy Violation Error PUT ${response.url}`, {
        error,
        tags: { url: response.url },
      })

      analytics.track('Policy Violation Error', {
        error,
      })

      throw new errors.PolicyViolationError(error.message)
    }

    if (response.status === 424 && error?.reason === 'Policy Violation') {
      observability.captureException(`Azure OpenAi Policy Violation Error PUT ${response.url}`, {
        error,
        tags: { url: response.url },
      })

      analytics.track("Azure OpenAI's content management policy", {
        error,
      })

      throw new errors.PolicyViolationError(error.message)
    }
    handleUnknownError(error, args)
  }

  return {
    postCadData,
  }
}
